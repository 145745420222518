import axios from "axios";
import config from "../../config";
import Constants from "../../common/constant";

/* 
    Note: This jest.mock("axios"); is used to mock Jest for test cases;
    in case of development it should be commented out to prevent below console errors 
    Warning: An update to Field ran an effect, but was not wrapped in act(...)
*/

const isTestingEnvironment = process.env.NODE_ENV === Constants.APPLICATION_ENVIRONMENT.TEST;
if (!isTestingEnvironment) {
 //  window.jest = { mock: () => {} };
}

// jest.mock("axios");

axios.create({
 baseURL: config.apiRootUrl,
 headers: {
  "Content-type": "application/json",
  "x-api-key": config.apiRootKeyTenant,
 },
});

export default axios;
