import { lazy } from "react";

export const TenantNestedMenu = [
 {
  name: "Dashboard",
  path: "/advisor/",
  component: lazy(() => import("../view/dashboard/dashboard")),
 },
 {
  name: "RevenueDetails",
  path: "/advisor/dashboard/revenueDetails",
  component: lazy(() => import("../view/tanents/revenueDetails")),
 },
 {
  name: "RevenueSubscription",
  path: "/advisor/dashboard/revenueSubscription",
  component: lazy(() => import("../view/tanents/revenueSubscription")),
 },
 {
  name: "TenantServices",
  path: "/advisor/profile/tenantServices",
  component: lazy(() => import("../view/tanents/tenantServices")),
 },
 {
  name: "RiskProfiling",
  path: "/advisor/profile/riskprofiling",
  component: lazy(() => import("../view/tanents/riskprofiling")),
 },
 {
  name: "DiscountCoupon",
  path: "/advisor/discountcoupon/create",
  component: lazy(() => import("../view/tanents/couponCreate")),
 },
 {
  name: "DiscountCoupon",
  path: "/advisor/discountcoupon/edit",
  component: lazy(() => import("../view/tanents/couponEdit")),
 },
 {
  name: "DiscountCouponList",
  path: "/advisor/discountcoupon/list",
  component: lazy(() => import("../view/tanents/couponList")),
 },
 {
  name: "Services",
  path: "/advisor/service/services",
  component: lazy(() => import("../view/tanents/services")),
 },
 {
  name: "ActivePortfolios",
  path: "/advisor/service/activePortfolios",
  component: lazy(() => import("../view/tanents/activeportfolios")),
 },
 {
  name: "DraftPortfolios",
  path: "/advisor/service/draftPortfolios",
  component: lazy(() => import("../view/tanents/draftportfolios")),
 },
 {
  name: "EditServices",
  path: "/advisor/service/editServices",
  component: lazy(() => import("../view/tanents/serviceedit.js")),
 },
 {
  name: "Pricing",
  path: "/advisor/service/pricing",
  component: lazy(() => import("../view/tanents/servicepricing")),
 },
 {
  name: "Rebalance",
  path: "/advisor/service/rebalance",
  component: lazy(() => import("../view/tanents/rebalanceTab")),
 },
 {
  name: "ClientInvestment",
  path: "/advisor/analytics/clientInvestment",
  component: lazy(() => import("../view/tanents/clientInvestment")),
 },
 {
  name: "StockHolding",
  path: "/advisor/analytics/stockHolding",
  component: lazy(() => import("../view/tanents/stockHolding")),
 },
 {
  name: "SectorHolding",
  path: "/advisor/analytics/sectorHolding",
  component: lazy(() => import("../view/tanents/sectorHolding")),
 },
 {
  name: "PortfolioHistory",
  path: "/advisor/analytics/portfolioHistory",
  component: lazy(() => import("../view/tanents/portfolioHistory")),
 },
 {
  name: "Factsheet",
  path: "/advisor/documents/factsheet",
  component: lazy(() => import("../view/tanents/factsheet")),
 },
 {
  name: "InvestmentReport",
  path: "/advisor/documents/investmentReport",
  component: lazy(() => import("../view/tanents/investmentReport")),
 },
 {
  name: "RebalanceReportHistory",
  path: "/advisor/documents/rebalanceReportHistory",
  component: lazy(() => import("../view/tanents/rebalanceReportHistory")),
 },

 // reports
 {
  name: "ClientReport",
  path: "/advisor/reports/clientReport",
  component: lazy(() => import("../view/tanents/clientReport")),
 },
 {
  name: "ClientReport_ReportSummary",
  path: "/advisor/reports/clientReport/reportSummary",
  component: lazy(() => import("../view/tanents/clientSummary.js")),
 },

 {
  name: "changePassword",
  path: "/advisor/changePassword",
  component: lazy(() => import("../view/tanents/changePassword.js")),
 },

 {
  name: "InvestmentReport",
  path: "/advisor/reports/investmentReportDownload",
  component: lazy(() => import("../view/tanents/investmentReportDownload")),
 },
 {
  name: "InvestmentReportDownload_ReportSummary",
  path: "/advisor/reports/investmentReportDownload/reportSummary",
  component: lazy(() => import("../view/tanents/investmentSummary.js")),
 },

 {
  name: "SubscriptionReport",
  path: "/advisor/reports/subscriptionReport",
  component: lazy(() => import("../view/tanents/subscriptionReport")),
 },
 {
  name: "SubscriptionReport_ReportSummary",
  path: "/advisor/reports/subscriptionReport/reportSummary",
  component: lazy(() => import("../view/tanents/subscriptionSummary.js")),
 },
 {
  name: "Settings",
  path: "/advisor/settings",
  component: lazy(() => import("../view/tanents/settings.js")),
},
];
